import BasePage from '../../../../../../../found/components/table_page';
import request from '../../../../../../../found/utils/request';

export default {
  name: 'detail',
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {
      requestType: 'GET',
    };
  },
  methods: {
    afterGetList() {
      this.exportParams = this.paramsProps;
      return true;
    },
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'taxPrice') {
        rowData.formatter = ({ row }) => {
          if (row.hide) {
            return '****';
          }
          return row.taxPrice;
        };
      }
      if (rowData.field === 'totalAmount') {
        rowData.formatter = ({ row }) => {
          if (row.hide) {
            return '****';
          }
          return row.totalAmount;
        };
      }
      return rowData;
    },
    // 获取列表数据
    getList() {
      if (this.beforeGetList()) {
        const { searchFormData, tablePage } = this;
        const params = {
          ...this.params,
          ...searchFormData,
          pageNum: tablePage.currentPage,
          pageSize: tablePage.pageSize,
        };
        if (this.requestType === 'GET') {
          params.page = tablePage.currentPage;
          params.size = tablePage.pageSize;
        }
        Object.assign(params, this.paramsProps ? this.paramsProps : {});
        if (!this.requestUrl) return Promise.resolve();
        if (!this.isPageChange) {
          this.selection = [];
          this.selectRow = [];
        }
        this.isPageChange = false;
        if (this.dataSource && this.dataSource instanceof Array) {
          this.tableData = this.dataSource;
        } else {
          this.tableLoading = true;
          if (this.requestType === 'POST') {
            return request
              .post(
                this.requestUrl,
                { ...params },
                { headers: { functionCode: this.headerFunctionCode } },
              )
              .then((res) => {
                if (res.success) {
                  this.tableLoading = false;
                  if (res.result.data) {
                    this.tableData = res.result.data;
                    this.tablePage.totalResult = res.result.count;
                  } else if (res.result.length >= 0) {
                    this.tableData = res.result;
                  } else {
                    this.tableData = [];
                  }
                  this.afterGetList();
                  this.setSelectData();
                }
              });
          } if (this.requestType === 'GET') {
            return request
              .get(
                this.requestUrl,
                {
                  ...params,
                },
                { headers: { functionCode: this.headerFunctionCode } },
              )
              .then((res) => {
                if (res.success) {
                  this.tableLoading = false;
                  if (res.result.length >= 0) {
                    this.tableData = res.result;
                  }
                  this.afterGetList();
                  this.setSelectData();
                }
              });
          }
        }
      }
    },
  },
  created() {
    this.getConfigList('dms_shipment_details');
  },
};
